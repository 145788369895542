<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card>
      <v-card-title class="display-2">Create a group</v-card-title>

      <v-card-title class="subtitle">Step 1: Name your group</v-card-title>
      <v-card-text>      
        <v-alert v-if="error" type="error">{{error}}</v-alert>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field 
            v-model="name" 
            :rules="nameRules" 
            label="Group name (required)" 
            prepend-icon="fa-pencil-alt"
            required>
          </v-text-field>
          
          <v-btn class="mt-8" block :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit">Create group</v-btn>
        </v-form>
      </v-card-text>   
    </v-card>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';

export default {
  name: "GroupCreate",
  components: {
  },
  props: {
  },
  data() {
    return {
      breadcrumbItems: [
        { text: 'Profile', exact: true, to: { name: 'profile' } },
        { text: 'Groups', exact: true, to: { name: 'profileGroups' } },
        { text: 'Create a Group', disabled: true },
      ],
      valid: true,
      error: null,
      name: "",
      nameRules: [
        //v => !!v || "Please enter the group name",
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          var response = await profileService.createGroup({
            name: this.name,
          });
          this.$router.push({ name: 'profileGroupsEdit', params: { id: response.data.id } });
        }
        catch (ex) {
          this.error = ex.response.data.msg;
        }
      }
    },

    async getProfile() {
      if (this.user) {
        //var response = await profileService.get();
        //this.profile = response.data;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

